import { render, staticRenderFns } from "./contact.vue?vue&type=template&id=3a5ba820&scoped=true&"
import script from "./contact.vue?vue&type=script&lang=js&"
export * from "./contact.vue?vue&type=script&lang=js&"
import style0 from "./contact.vue?vue&type=style&index=0&id=3a5ba820&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a5ba820",
  null
  
)

export default component.exports